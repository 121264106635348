/// <reference types="node" />
/// <reference types="vite/client" />
import { CN_DOMAIN, COM_CN_DOMAIN, INTL_DOMAIN } from './constant';

export const Self = (() => {
  if (typeof globalThis !== 'undefined') return globalThis as unknown as Window;
  return window;
})();
/**
 * 本地开发环境
 */
export const isLocal = import.meta.env.DEV || process.env.NODE_ENV === 'development';
/**
 * dev.tccc.qcloud.com
 */
export const isDev = process.env.REACT_APP_MODE === 'dev' || import.meta.env.VITE_APP_MODE === 'dev';
/**
 * test.tccc.qcloud.com
 */
export const isTest = process.env.REACT_APP_MODE === 'test' || import.meta.env.VITE_APP_MODE === 'test';
/**
 * tccc.qcloud.com
 */
export const isProd = process.env.REACT_APP_MODE === 'prod' || import.meta.env.VITE_APP_MODE === 'prod';

const getOrigin = (origin?: string) => {
  if (!origin) return origin;
  try {
    return new URL(origin).hostname;
  } catch (e) {
    return origin;
  }
};

/**
 * 国际站暂时没有测试环境，可能会本地测试
 * connect.tencentcloud.com
 */
export const isInternational = (origin?: string) => {
  if (isLocal || isProd) {
    return (
      Self.location.hostname === INTL_DOMAIN || import.meta.url === INTL_DOMAIN || getOrigin(origin) === INTL_DOMAIN
    );
  }
  return false;
};

/**
 * ccc.tencent.com.cn
 */
export const isComCn = (origin?: string) =>
  Self.location.hostname === COM_CN_DOMAIN || import.meta.url === COM_CN_DOMAIN || getOrigin(origin) === COM_CN_DOMAIN;
/**
 * ccc.tencent.cn
 */
export const isCn = (origin?: string) =>
  Self.location.hostname === CN_DOMAIN || import.meta.url === CN_DOMAIN || getOrigin(origin) === CN_DOMAIN;

// 根据有没有这个环境变量判断是SDK或者是SaaS
export const isSdkV2 = Boolean(process.env.VITE_APP_MODE);
