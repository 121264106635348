import React, { useState, useEffect } from 'react';

import styled, { keyframes } from 'styled-components';

interface MessageStreamProps {
  data: {
    chunks?: string[];
    isFinished?: number;
  };
}

const blink = keyframes`
  0%, 100% {
    background-color: transparent;
  }
  50% {
    background-color: black;
  }
`;

const MessageStreamContainer = styled.div`
  word-break: break-all;
  font-size: 14px;
  padding: 12px;
`;

const BlinkingCursor = styled.span`
  display: inline-block;
  width: 1px;
  height: 16px;
  background-color: black;
  animation: ${blink} 1s step-end infinite;
  vertical-align: sub;
`;

const MessageStream: React.FC<MessageStreamProps> = (props) => {
  const [content, setContent] = useState<string>('');
  const [isFinished, setIsFinished] = useState<boolean>(false);

  useEffect(() => {
    if (Array.isArray(props?.data?.chunks)) {
      const validChunks = props.data.chunks.filter((chunk) => typeof chunk === 'string');
      setContent(validChunks.join(''));
    } else {
      setContent('');
    }
  }, [props]);

  useEffect(() => {
    setIsFinished(props?.data?.isFinished === 1);
  }, [props]);

  return (
    <MessageStreamContainer>
      {content}
      {!isFinished && <BlinkingCursor />}
    </MessageStreamContainer>
  );
};

export default MessageStream;
