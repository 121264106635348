import { useRef, useContext, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import { MessageListScrollContext } from '../../im-list/ImListInner';

import style from './VideoElement.style';

const useStyles = makeStyles(style);

export default function VideoElement(props) {
  const classes = useStyles();
  const scrollContext = useContext(MessageListScrollContext);
  const ref = useRef(null);

  useEffect(() => {
    const videoElement = ref.current;

    if (videoElement) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const {} of entries) {
          scrollContext?.mediaLoadedSrcroll(videoElement.clientHeight);
        }
      });

      resizeObserver.observe(videoElement);

      // 清理函数，在组件卸载时取消观察
      return () => {
        resizeObserver.unobserve(videoElement);
        resizeObserver.disconnect();
      };
    }
  }, []);

  const { payload, _elements: elements } = props.data;
  if (!payload) return null;
  const videoSrcFromElements = elements?.find((i) => i.type === 'TIMVideoFileElem')?.MsgContent?.VideoUrl;
  const videoSrc = payload.videoUrl || videoSrcFromElements;
  const playVideo = () => {
    ref.current.play();
  };

  return (
    <div className={classes.root} onClick={playVideo}>
      <video controls src={videoSrc} ref={ref} className={classes.video} />
    </div>
  );
}

VideoElement.propTypes = {
  data: PropTypes.object.isRequired,
};
