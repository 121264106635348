import { forwardRef, useImperativeHandle, useRef, createContext, createRef, Component } from 'react';

import last from 'lodash/last';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';

import { ImAsideWrapper } from '../im-layout/im-aside/imAside.style';
import { ImCenterWrapper } from '../im-layout/im-center/ImCenter.style';

export const MessageListWrapper = styled(PerfectScrollbar)`
  padding: 10px 0;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  padding-bottom: 30px;
`;
export const MessageListScrollContext = createContext();

class MessageListInner extends Component {
  constructor(props) {
    super(props);

    this.scrollPointRef = createRef();
    this.containerRef = createRef();
    this.scrollRef = createRef();
    this.lastClientHeight = 0;
    this.preventScrollTop = false;
    this.resizeObserver = undefined;
    this.scrollTicking = false;
    this.resizeTicking = false;
    this.noScroll = undefined;
  }

  getSnapshotBeforeUpdate() {
    const list = this.containerRef.current;

    const topHeight = Math.round(list.scrollTop + list.clientHeight);
    // 1 px fix for firefox
    const sticky =
      list.scrollHeight === topHeight || list.scrollHeight + 1 === topHeight || list.scrollHeight - 1 === topHeight;

    return {
      sticky,
      clientHeight: list.clientHeight,
      scrollHeight: list.scrollHeight,
      lastMessageOrGroup: this.getLastMessageOrGroup(),
      diff: list.scrollHeight - list.scrollTop,
    };
  }

  handleResize = () => {
    // If container is smaller than before resize - scroll to End
    if (this.containerRef.current.clientHeight < this.lastClientHeight) {
      this.scrollToEnd(this.props.scrollBehavior);
    }

    this.scrollRef.current.updateScroll();
  };

  handleContainerResize = () => {
    if (this.resizeTicking === false) {
      window.requestAnimationFrame(() => {
        const list = this.containerRef.current;

        if (list) {
          const currentHeight = list.clientHeight;

          const diff = currentHeight - this.lastClientHeight;

          if (diff >= 1) {
            // Because fractional

            if (this.preventScrollTop === false) {
              list.scrollTop = Math.round(list.scrollTop) - diff;
            }
          } else {
            list.scrollTop = list.scrollTop - diff;
          }

          this.lastClientHeight = list.clientHeight;

          this.scrollRef.current.updateScroll();
        }

        this.resizeTicking = false;
      });

      this.resizeTicking = true;
    }
  };

  isSticked = () => {
    const list = this.containerRef.current;

    return list.scrollHeight === Math.round(list.scrollTop + list.clientHeight);
  };

  handleScroll = () => {
    if (this.scrollTicking === false) {
      window.requestAnimationFrame(() => {
        if (this.noScroll === false) {
          this.preventScrollTop = this.isSticked();
        } else {
          this.noScroll = false;
        }

        this.scrollTicking = false;
      });

      this.scrollTicking = true;
    }
  };

  componentDidMount() {
    // Set scrollbar to bottom on start (getSnaphotBeforeUpdate is not invoked on mount)
    if (this.props.autoScrollToBottomOnMount === true) {
      this.scrollToEnd(this.props.scrollBehavior);
    }

    this.lastClientHeight = this.containerRef.current.clientHeight;

    window.addEventListener('resize', this.handleResize);

    if (typeof window.ResizeObserver === 'function') {
      this.resizeObserver = new ResizeObserver(this.handleContainerResize);
      this.resizeObserver.observe(this.containerRef.current);
    }
    this.containerRef.current.addEventListener('scroll', this.handleScroll);
  }
  getLastMessageOrGroup = () => {
    const messageElements = this.containerRef.current.querySelectorAll(
      `${MessageListWrapper}>${ImCenterWrapper},${MessageListWrapper}>${ImAsideWrapper}`,
    );
    return {
      lastElement: last(messageElements),
    };
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      props: { autoScrollToBottom },
    } = this;

    if (typeof snapshot !== 'undefined') {
      const list = this.containerRef.current;

      const { lastElement, lastMessageInGroup } = this.getLastMessageOrGroup();
      if (lastElement === snapshot.lastMessageOrGroup.lastElement) {
        // If lastMessageInGroup is defined last element is MessageGroup otherwise its Message
        if (
          typeof lastMessageInGroup === 'undefined' ||
          lastMessageInGroup === snapshot.lastMessageOrGroup.lastMessageInGroup
        ) {
          list.scrollTop = list.scrollHeight - snapshot.diff + (this.lastClientHeight - list.clientHeight);
        }
      }

      if (snapshot.sticky === true) {
        if (autoScrollToBottom === true) {
          this.scrollToEnd(this.props.scrollBehavior);
        }
        this.preventScrollTop = true;
      } else {
        if (snapshot.clientHeight < this.lastClientHeight) {
          // If was sticky because scrollHeight is not changing, so here will be equal to lastHeight plus current scrollTop
          // 1px fix id for firefox
          const sHeight = list.scrollTop + this.lastClientHeight;
          if (list.scrollHeight === sHeight || list.scrollHeight + 1 === sHeight || list.scrollHeight - 1 === sHeight) {
            if (autoScrollToBottom === true) {
              this.scrollToEnd(this.props.scrollBehavior);
              this.preventScrollTop = true;
            }
          } else {
            this.preventScrollTop = false;
          }
        } else {
          this.preventScrollTop = false;

          if (
            lastElement === snapshot.lastMessageOrGroup.lastElement ||
            lastElement?.dataset?.messageId === snapshot.lastMessageOrGroup.lastElement?.dataset?.messageId
          ) {
            if (
              typeof lastMessageInGroup === 'undefined' ||
              lastMessageInGroup === snapshot.lastMessageOrGroup.lastMessageInGroup
            ) {
              // New elements were not added at end
              // New elements were added at start
              if (list.scrollTop === 0 && list.scrollHeight > snapshot.scrollHeight) {
                list.scrollTop = list.scrollHeight - snapshot.scrollHeight;
              }
            }
          }
        }
      }

      this.lastClientHeight = snapshot.clientHeight;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    if (typeof this.resizeObserver !== 'undefined') {
      this.resizeObserver.disconnect();
    }
    this.containerRef.current.removeEventListener('scroll', this.handleScroll);
  }

  scrollToEnd(scrollBehavior = this.props.scrollBehavior) {
    const list = this.containerRef.current;
    const scrollPoint = this.scrollPointRef.current;

    // https://stackoverflow.com/a/45411081/6316091
    const parentRect = list?.getBoundingClientRect();
    const childRect = scrollPoint?.getBoundingClientRect();

    // Scroll by offset relative to parent
    const scrollOffset = childRect.top + list.scrollTop - parentRect.top;

    if (list.scrollBy) {
      list.scrollBy({ top: scrollOffset, behavior: scrollBehavior });
    } else {
      list.scrollTop = scrollOffset;
    }

    this.lastClientHeight = list.clientHeight;

    // Important flag! Blocks strange Chrome mobile behaviour - automatic scroll.
    // Chrome mobile sometimes trigger scroll when new content is entered to MessageInput. It's probably Chrome Bug - sth related with overflow-anchor
    this.noScroll = true;
  }

  invalidScrollToEnd(scrollBehavior = this.props.scrollBehavior) {
    if (this.props.autoScrollToBottom === true) {
      this.scrollToEnd(scrollBehavior);
    }
  }

  mediaLoadedSrcroll = (mediaElementHeight) => {
    try {
      const list = this.containerRef.current;
      if (list?.scrollTop + list?.clientHeight >= list?.scrollHeight - mediaElementHeight) {
        this.scrollToEnd(this.props.scrollBehavior);
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const {
      props: {
        children,
        typingIndicator,
        loading,
        loadingMore,
        loadingMorePosition,
        onYReachStart,
        onYReachEnd,
        className,
        onScrollY,
        disableOnYReachWhenNoScroll,
        scrollBehavior, // Just to remove rest
        autoScrollToBottom, // Just to remove rest
        autoScrollToBottomOnMount, // Just to remove rest,
        ...rest
      },
    } = this;

    return (
      <div {...rest}>
        <MessageListScrollContext.Provider
          value={{
            invalidScrollToEnd: this.invalidScrollToEnd.bind(this),
            scrollToEnd: this.scrollToEnd.bind(this),
            mediaLoadedSrcroll: this.mediaLoadedSrcroll.bind(this),
          }}
        >
          <MessageListWrapper
            onYReachStart={onYReachStart}
            onYReachEnd={onYReachEnd}
            onScrollY={onScrollY}
            onSync={(ps) => ps.update(disableOnYReachWhenNoScroll)}
            ref={this.scrollRef}
            containerRef={(ref) => {
              this.containerRef.current = ref;
            }}
            options={{ suppressScrollX: true }}
            style={{
              overscrollBehaviorY: 'none',
              overflowAnchor: 'auto',
              touchAction: 'none',
            }}
          >
            {children}
            <div className={`${MessageListWrapper}__scroll-to`} ref={this.scrollPointRef} />
          </MessageListWrapper>
          {typeof typingIndicator !== 'undefined' && (
            <div className={`${MessageListWrapper}__typing-indicator-container`}>{typingIndicator}</div>
          )}
        </MessageListScrollContext.Provider>
      </div>
    );
  }
}

MessageListInner.displayName = 'MessageList';

function MessageListFunc(props, ref) {
  const msgListRef = useRef();

  const scrollToBottom = (scrollBehavior) => msgListRef.current.scrollToEnd(scrollBehavior);

  // Return object with public Api
  useImperativeHandle(ref, () => ({
    scrollToBottom,
  }));

  return <MessageListInner ref={msgListRef} {...props} />;
}

const MessageList = forwardRef(MessageListFunc);

MessageList.defaultProps = {
  typingIndicator: undefined,
  loading: false,
  loadingMore: false,
  loadingMorePosition: 'top',
  disableOnYReachWhenNoScroll: false,
  autoScrollToBottom: true,
  autoScrollToBottomOnMount: true,
  scrollBehavior: 'auto',
};

MessageListInner.propTypes = MessageList.propTypes;
MessageListInner.defaultProps = MessageList.defaultProps;

export default MessageList;
