import { useRef, useEffect, useCallback, useState, useMemo } from 'react';

import debounce from 'lodash/debounce';
import findLast from 'lodash/findLast';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';
import { TIMSDK as tim } from 'tccc-sdk';

import {
  CUSTOM_MESSAGE_SRC,
  IM_MESSAGE_EXTRA_TYPE,
  IM_STATUS,
  ROBOT_COMMAND,
  IMROBOT_MESSAGE_TYPE_LIST,
} from 'src/constants/im';
import i18n from 'src/i18n';
import { selectBySessionId } from 'src/store';
import {
  getMessageList as fetchMessageList,
  initMessageList,
  sendMessageReadReceipt,
  setMessageRead,
  updateImageViewer,
} from 'src/store/slices/tim.thunk';
import logger from 'src/utils/logger';
import { getMessageFrom } from 'src/utils/message';

import { updateUnreadCount } from 'src/utils/titleNotification';

import {
  FileElement,
  ImageElement,
  LocationElement,
  MediaElement,
  SoundElement,
  TextElement,
  RobotElement,
  VideoElement,
  InfoElement,
  RevokeMessageElement,
} from '../im-elements';
import { CardElement } from '../im-elements/card-element/CardElement';
import MessageChatbotInfoCollect from '../im-elements/chatbot-info-collect-element/ChatbotInfoCollectElement';
import ImageViewer from '../im-elements/image-element/ImageViewer';
import { ImRobotElement } from '../im-elements/imRobot-element/ImRobotElement';
import { OrderLement } from '../im-elements/order-element/Order-element';
import MessageStream from '../im-elements/streamText-element/StreamTextElement';
import { UserCustomElement } from '../im-elements/userCustom-element/userCustomElement';
import { ImAside, ImCenter } from '../im-layout';
import { TypingTip, LoadingTip, ErrorTip } from '../im-tips';

import { MessageListBox, MessageListWrapper, ShowMoreButton } from './imList.style';
import MessageListInner from './ImListInner';
import { Loader } from './Loader';

// 需要居中的消息类型
const messageToCentered = [IM_MESSAGE_EXTRA_TYPE.INFO];
// 需要居中的自定义消息类型性
const customMessageToCentered = [CUSTOM_MESSAGE_SRC.MENU];
const { t } = i18n;
const getCustomMessageData = (message) => {
  let data = get(message, 'payload.data', {});
  try {
    if (typeof data === 'string') {
      data = JSON.parse(data);
    }
  } catch (e) {
    data = {};
  }
  return data;
};

function getCustomComponent(message) {
  let data = getCustomMessageData(message);
  let component = null;

  try {
    if (typeof data === 'string') {
      data = JSON.parse(data);
    }
  } catch (e) {
    data = {};
  }
  if (data?.fromPostMessage === 1) {
    return <UserCustomElement data={data} />;
  }
  if (data?.chatbotPlugin === 1) {
    // 现在只展示src为9的富文本消息
    // src为15的欢迎卡片与猜你想问的卡片现在不渲染
    if (
      data?.src === IMROBOT_MESSAGE_TYPE_LIST.RICHTEXT &&
      data?.content !== null &&
      data?.content !== '' &&
      data?.content !== undefined
    ) {
      return <ImRobotElement data={data} />;
    }
  }
  switch (data.src || data.type) {
    case CUSTOM_MESSAGE_SRC.ROBOT:
      switch (data.content.command) {
        case ROBOT_COMMAND.SHOW_DIALOG:
          return <RobotElement data={data} />;
        default:
          return null;
      }
    case CUSTOM_MESSAGE_SRC.CustomCard:
      component = <CardElement {...get(data, 'content', {})} />;
      break;
    case CUSTOM_MESSAGE_SRC.ORDER_CARD:
      component = <OrderLement {...get(data, 'content', {})} />;
      break;
    case IM_MESSAGE_EXTRA_TYPE.MEDIA:
      component = <MediaElement data={data} />;
      break;
    case CUSTOM_MESSAGE_SRC.RICH_TEXT:
      component = <ImRobotElement data={data} />;
      break;
    case CUSTOM_MESSAGE_SRC.STREAM_TEXT:
      component = <MessageStream data={data} />;
      break;
    case CUSTOM_MESSAGE_SRC.NEW_BRANCH:
      component = (
        <div style={{ wordBreak: 'break-all', padding: '12px' }}>{data?.content?.header || data?.content?.title}</div>
      );
      break;
    case CUSTOM_MESSAGE_SRC.NEW_INFO_COLLECT: {
      component = <MessageChatbotInfoCollect message={message} data={data} />;
      break;
    }
    case '1':
    case '2':
    case '6':
    case '7':
    case 'ivr':
    case CUSTOM_MESSAGE_SRC.MENU:
    case CUSTOM_MESSAGE_SRC.MENU_SELECTED:
      component = null;
      break;
    default:
      component = null;
  }
  return component;
}
function getMessageWithLayout(component, message, index, callInfo, userInfo, list) {
  const data = getCustomMessageData(message);
  const from = getMessageFrom(message, callInfo, userInfo);
  if (
    messageToCentered.includes(message.type) ||
    (message.type === tim.TYPES.MSG_CUSTOM && customMessageToCentered.includes(data.src))
  ) {
    return <ImCenter key={`${message.ID}`} content={component} />;
  }
  const getMessageStatus = (message) => {
    switch (message.status) {
      case IM_STATUS.UN_SEND:
        if (message.flow === 'out' && message.readReceiptInfo.readCount > 1) return t('已读');
        return <LoadingTip />;
      case IM_STATUS.FAIL:
        return <ErrorTip message={message} />;
      default:
        return null;
    }
  };

  return (
    <ImAside
      renderBackground={![tim.TYPES.MSG_FILE, tim.TYPES.MSG_IMAGE].includes(message.type)}
      message={message}
      callInfo={callInfo}
      userInfo={userInfo}
      list={list}
      key={`${message.ID}`}
      content={component}
      status={getMessageStatus(message)}
      header={from}
    >
      {component}
    </ImAside>
  );
}
function getMessageTime(timestamp, lastTime) {
  const time = moment.unix(timestamp);
  if (time.isSameOrAfter(moment().startOf('day'))) {
    const diff = timestamp - lastTime;
    if (!lastTime || diff > 300) {
      return moment.unix(timestamp).format('HH:mm');
    }
    return;
  }
  const preMoment = moment.unix(lastTime);
  if (time.isSameOrAfter(moment().startOf('isoWeek'))) {
    if (!lastTime || !time.isSame(preMoment, 'date') || time.diff(preMoment, 'minutes') >= 30) {
      return `${
        [t('星期日'), t('星期一'), t('星期二'), t('星期三'), t('星期四'), t('星期五'), t('星期六'), t('星期日')][
          time.get('day')
        ]
      } ${time.format('HH:mm')}`;
    }
    return;
  }
  if (!lastTime || !time.isSame(preMoment, 'date') || time.diff(preMoment, 'hour') >= 1) {
    return time.format('YYYY-MM-DD HH:mm');
  }
}
function getMessageList(list, callInfo, userInfo, reSend = () => {}) {
  return list?.reduce(
    (pre, item, index) => {
      let component = null;
      const { type } = item;
      const payload = get(item, 'payload');
      if (item.isRevoked) {
        pre.elements.push(
          <ImCenter
            key={`${item.ID}-${index}`}
            content={<RevokeMessageElement reSend={reSend} message={item} callInfo={callInfo} />}
          />,
        );
        return pre;
      }
      switch (type) {
        case tim.TYPES.MSG_GRP_TIP:
        case tim.TYPES.MSG_GRP_SYS_NOTICE:
        case tim.TYPES.MSG_CUSTOM:
          component = getCustomComponent(item, index);
          break;
        case tim.TYPES.MSG_TEXT:
          component = <TextElement data={item} index={index} />;
          break;
        case tim.TYPES.MSG_IMAGE:
          component = <ImageElement data={item} index={index} />;
          break;
        case tim.TYPES.MSG_FILE:
          component = <FileElement data={item} index={index} />;
          break;
        case IM_MESSAGE_EXTRA_TYPE.INFO:
          component = <InfoElement info={payload.text} />;
          break;
        case tim.TYPES.MSG_SOUND:
          component = <SoundElement data={item} index={index} />;
          break;
        case tim.TYPES.MSG_VIDEO:
          component = <VideoElement data={item} index={index} />;
          break;
        case tim.TYPES.MSG_GEO:
          component = <LocationElement data={item} index={index} />;
          break;
        default:
          logger.warn(`暂未支持的消息类型：${item.type || item.payload?.data?.type || item.payload?.data?.src}`);
          component = null;
      }
      if (component) {
        const time = getMessageTime(item.time, pre.lastTime);
        if (time) {
          pre.lastTime = item.time;
          pre.elements.push(<ImCenter key={`time-${item.ID}`} content={<InfoElement info={time} />} />);
        }
        pre.elements.push(getMessageWithLayout(component, item, index, callInfo, userInfo, list));
      }
      return pre;
    },
    { elements: [], lastTime: -1 },
  ).elements;
}

export function ImList(props) {
  const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const listComponentRef = useRef();
  const { currentUser } = userInfo;
  const currentCallInfo = useSelector((state) => selectBySessionId(state, currentUser.sessionId));
  const innerConv = useSelector((state) => state.tim.innerConversationList).find(
    (item) => item.sessionId === currentUser.sessionId,
  );
  const targetMessage = useSelector((state) => state.tim.messageList[currentUser.sessionId]);
  const targetTypingState = useSelector((state) => state.tim.typingStateList[currentUser.sessionId]);
  const imageViewer = useSelector((state) => state.tim.imageViewer);
  const list = targetMessage?.list || [];
  const imagesFromMessageList = list?.filter((item) => item.type === tim.TYPES.MSG_IMAGE && item.isRevoked !== true);
  const lastItem = findLast(list, (item) => item.type !== tim.TYPES.MSG_CUSTOM);
  const messageComponents = getMessageList(list, currentCallInfo, userInfo, props?.reSend);
  const [loadingMore, setLoadingMore] = useState();
  const [scrolled, setScrolled] = useState(false);

  const getMoreMessage = useCallback(
    debounce(
      () => {
        if (targetMessage?.isCompleted || loadingMore) {
          return;
        }
        setLoadingMore(true);
        const preBoxHeight = listComponentRef.current?.scrollHeight || 0;
        if (currentCallInfo?.status === '200' || innerConv) {
          dispatch(
            fetchMessageList({
              sessionId: currentUser.sessionId,
              nextReqMessageID: targetMessage?.nextReqMessageID,
            }),
          )
            .then(() => {
              setLoadingMore(false);
              if (listComponentRef.current) {
                listComponentRef.current.scrollTop = listComponentRef.current.scrollHeight - preBoxHeight;
              }
            })
            .catch(() => {
              setLoadingMore(false);
            });
        }
      },
      1000,
      { leading: true },
    ),
    [
      targetMessage?.isCompleted,
      targetMessage?.nextReqMessageID,
      loadingMore,
      currentCallInfo?.status,
      innerConv,
      dispatch,
      currentUser.sessionId,
    ],
  );

  useEffect(() => {
    if (lastItem?.flow === 'out' || lastItem?.from === 'administrator') {
      listComponentRef.current?.scrollToBottom && listComponentRef.current.scrollToBottom();
    }
  }, [lastItem, listComponentRef]);
  useEffect(() => {
    if (loadingMore) {
      return;
    }
    setLoadingMore(true);
    if (currentCallInfo?.status === '200' || innerConv) {
      dispatch(
        initMessageList({
          sessionId: currentUser.sessionId,
        }),
      )
        .then(() => {
          setLoadingMore(false);
          if (listComponentRef.current) {
            listComponentRef.current.scrollToBottom();
          }
        })
        .catch(() => {
          setLoadingMore(false);
        });
    }
  }, [currentUser.sessionId]);

  const checkRead = useCallback(
    debounce(() => {
      setScrolled(true);
      if (listComponentRef.current) {
        const container = document.querySelector('#messageBox');
        const containerRect = container.getBoundingClientRect();
        const messageIds = Array.from(container.querySelectorAll('[data-message-id][data-unread]'))
          .filter((element) => {
            const elementBounds = element.getBoundingClientRect();
            return elementBounds.bottom >= containerRect.top && elementBounds.top <= containerRect.bottom;
          })
          .map((element) => element.dataset.messageId);
        if (!isEmpty(messageIds)) {
          dispatch(sendMessageReadReceipt({ messageIds, sessionId: currentUser.sessionId }));
        }
        const lastElement = lastItem?.ID && container.querySelector(`[data-message-id="${lastItem.ID}"]`);
        if (lastElement) {
          const elementBounds = lastElement.getBoundingClientRect();
          if (elementBounds.bottom >= containerRect.top && elementBounds.top <= containerRect.bottom) {
            dispatch(setMessageRead({ sessionId: currentUser.sessionId }));
            updateUnreadCount(0);
          }
        }
      }
    }, 1000),
    [listComponentRef, currentUser.sessionId, lastItem?.ID, setScrolled, dispatch],
  );

  useEffect(() => {
    document.addEventListener('visibilitychange', checkRead);
    return () => {
      document.removeEventListener('visibilitychange', checkRead);
    };
  }, [checkRead]);
  useEffect(() => {
    if (document.visibilityState === 'visible') checkRead();
  }, [checkRead, currentUser.sessionId, lastItem?.ID]);

  const MemoImageViewer = useMemo(
    () => (
      <ImageViewer
        imageMessageList={imagesFromMessageList}
        currentImageMessageID={imageViewer.currentImageId}
        onClose={() => dispatch(updateImageViewer({ visible: false }))}
      />
    ),
    [imagesFromMessageList, imageViewer.currentImageId, dispatch],
  );

  if (!targetMessage) {
    return null;
  }

  return (
    <>
      <MessageListWrapper id="messageBox">
        <MessageListBox>
          <MessageListInner
            ref={listComponentRef}
            onScrollY={checkRead}
            typingIndicator={<TypingTip isTyping={targetTypingState} />}
            onYReachStart={() => scrolled && getMoreMessage()}
          >
            {(currentUser.sessionId.startsWith('C2C') || currentCallInfo?.status === '200') &&
              !targetMessage?.isCompleted && (
                <ShowMoreButton type="link" onClick={getMoreMessage}>
                  {loadingMore ? <Loader /> : t('查看更多')}
                </ShowMoreButton>
              )}
            {messageComponents}
          </MessageListInner>
        </MessageListBox>
      </MessageListWrapper>
      {imageViewer.visible && MemoImageViewer}
    </>
  );
}
