// 自定义消息类型
export const CUSTOM_MESSAGE_SRC = {
  // 公众号
  OFFICIAL_ACCOUNT: '1',
  // 小程序
  MINI_APP: '2',
  // 小程序服务号 MINI_APP_SERVICE_ACCOUNT: '3',
  // 后台内部
  BACKEND_INTERNAL: '4',
  // 网页
  WEB: '5',
  // 会话消息分割
  SESSION_MESSAGE_SLICE: '6',
  // 小程序自动触发
  MINI_APP_AUTO: '7',
  // 内部会话
  INTERNAL: '8',
  // 菜单消息
  MENU: '9',
  // 菜单选择
  MENU_SELECTED: '10',
  // 客户端在线状态
  CLIENT_STATE: '11',
  // 输入状态
  TYPING_STATE: '12',
  // 文本机器人
  ROBOT: '13',
  // 媒体状态变更消息
  MEDIA_CHANGE: '16',
  // s2c 2017 member change
  MEMBER: '17',
  // 无座席在线
  NO_SEAT_ONLINE: '18',
  // 会话正常结束
  END: '19',
  // 呼入超时
  TIMEOUT: '20',
  // 卡片元素
  CustomCard: '22',
  // 评价消息配置
  SATISFACTION_CONF: '23',
  // 用户主动拉取评价
  USER_SATISFACTION: '24',
  // 会话机器人状态
  ROBOT_STATUS: '25',
  // 会话人工状态
  SEAT_STATUS: '26',
  // 用户主动结束会话
  USER_END_SESSION: '27',
  // 订单消息
  ORDER_CARD: '28',
  // 机器人欢迎卡片
  WELCOME_CARD: '29',
  // 机器人富文本
  RICH_TEXT: '30',
  // 流式消息
  STREAM_TEXT: '31',
  // 多轮任务新分支
  NEW_BRANCH: '32',
  // 多轮任务信息采集
  NEW_INFO_COLLECT: '33',
};

// 对 im 消息类型的扩充
export const IM_MESSAGE_EXTRA_TYPE = {
  INFO: 'INFO',
  MEDIA: 'mediaMessage',
};

// 客服满意度评价状态
export const RATING_STATE = {
  NONE: 1,
  IN_PROGRESS: 2,
  DONE: 3,
};

// 客服满意度评价规则
export const RATING_SEND_RULE = {
  ALLOW_AUTO_SEND: 1,
  ALLOW_SERVICE_SEND: 2,
  ALLOW_CLIENT_SEND: 4,
};

// 下发满意度错误码
export const SEND_RATING_ERROE_CODE = {
  // 会话过期或还未开始
  SESSION_EXPIRED_OR_NOT_START: '-9006',
  // 重复操作
  DUPLICATE_SUBMIT: '-9501',
};

export const IM_USER_ONLINE_STATE = {
  ONLINE: true,
  OFFLINE: false,
};

export const ACTIVE_SESSION_STATE = {
  IN_PROGERSS: 'InProgress',
  FINISHED: 'finished',
};

// IM 消息状态
export const IM_STATUS = {
  SUCCESS: 'success',
  FAIL: 'fail',
  UN_SEND: 'unSend',
};

// 文本机器人指令
export const ROBOT_COMMAND = {
  UPDATE_BUBBLE: 'updateBubble',
  UPDATE_SEARCH_TIPS: 'updateSearchTips',
  SHOW_DIALOG: 'showDialog',
  FEEDBACK: 'feedback',
  SELECT_RECOMMEND: 'selectRecommend',
};

// 文本机器人消息类型
export const ROBOT_MESSAGE_TYPE = {
  SIMPLE_TEXT: 'simpleText',
  RICH_TEXT: 'richText',
  MULTI_LINE_TEXT: 'multiLineText',
  CANDIDATE_ANSWER: 'candidateAnswer',
  QUESTION_LIST: 'questionList',
};

// im 图片格式
export const IMAGE_FORMAT = {
  1: 'jpeg',
  2: 'gif',
  3: 'png',
  4: 'bmp',
};

// 转接类型
export const TRANSFER_TYPE = {
  AGENT: 1,
  SKILL_GROUP: 2,
};

export const INVISIBLE_MESSAGE_TYPE_LIST = [
  CUSTOM_MESSAGE_SRC.OFFICIAL_ACCOUNT,
  CUSTOM_MESSAGE_SRC.MINI_APP,
  CUSTOM_MESSAGE_SRC.BACKEND_INTERNAL,
  CUSTOM_MESSAGE_SRC.WEB,
  CUSTOM_MESSAGE_SRC.SESSION_MESSAGE_SLICE,
  CUSTOM_MESSAGE_SRC.MINI_APP_AUTO,
  CUSTOM_MESSAGE_SRC.INTERNAL,
  CUSTOM_MESSAGE_SRC.MENU_SELECTED,
  CUSTOM_MESSAGE_SRC.CLIENT_STATE,
  CUSTOM_MESSAGE_SRC.TYPING_STATE,
  CUSTOM_MESSAGE_SRC.MEDIA_CHANGE,
  CUSTOM_MESSAGE_SRC.MEMBER,
  CUSTOM_MESSAGE_SRC.NO_SEAT_ONLINE,
  CUSTOM_MESSAGE_SRC.END,
  CUSTOM_MESSAGE_SRC.TIMEOUT,
  CUSTOM_MESSAGE_SRC.SATISFACTION_CONF,
  CUSTOM_MESSAGE_SRC.USER_SATISFACTION,
  CUSTOM_MESSAGE_SRC.ROBOT_STATUS,
  CUSTOM_MESSAGE_SRC.SEAT_STATUS,
  CUSTOM_MESSAGE_SRC.USER_END_SESSION,
];

export const IMROBOT_MESSAGE_TYPE_LIST = {
  // 富文本消息
  RICHTEXT: 9,
};
