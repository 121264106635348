import React, { useRef, useEffect } from 'react';

import { styled } from '@material-ui/core';
import { message } from '@tencent/tea-component';
import { useTranslation } from 'react-i18next';
import { Message } from 'tccc-sdk';

import iconDownload from 'src/assets/images/download.svg';
import iconArrowLeft from 'src/assets/images/icon-arrow-left.svg';
import iconClose from 'src/assets/images/icon-close.svg';
import iconRotateLeft from 'src/assets/images/rotate-left.svg';
import iconRotateRight from 'src/assets/images/rotate-right.svg';
import iconZoomIn from 'src/assets/images/zoom-in.svg';
import iconZoomOut from 'src/assets/images/zoom-out.svg';

type MessageImageProps = {
  imageMessageList: Message[];
  currentImageMessageID: string;
  onClose: () => void;
};

const ImageViewerContainer = styled('div')({
  position: 'fixed',
  zIndex: 101,
  width: '100vw',
  height: '100vh',
  background: 'rgba(0,0,0, 0.3)',
  top: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  userSelect: 'none',
});

const ImageWrapper = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
});

const ImageList = styled('ul')({
  position: 'absolute',
  height: '100%',
  left: 0,
  padding: 0,
  margin: 0,
  display: 'flex',
  flexDirection: 'row',
  placeContent: 'center center',
});

const ImageContainer = styled('li')({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
});

const ImageItem = styled('img')({
  maxWidth: '100%',
  maxHeight: '100%',
  transition: 'transform 0.1s ease 0s',
  pointerEvents: 'auto',
});

const ImageClose = styled('div')({
  position: 'absolute',
  cursor: 'pointer',
  borderRadius: '50%',
  top: '3%',
  right: '3%',
  padding: '10px',
  background: 'rgba(255,255,255,0.8)',
  display: 'flex',
  width: '16px',
  height: '16px',
  '&::before': {
    backgroundColor: '#444',
  },
  '&::after': {
    backgroundColor: '#444',
  },
  '& img': {
    position: 'absolute',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    width: '14px',
    height: '14px',
  },
});

const ImagePreNext = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  cursor: 'pointer',
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  top: 'calc(50% - 20px)',
  background: 'rgba(255, 255, 255, 0.8)',
  '& img': {
    position: 'absolute',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  },
});

const ActionsBar = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  position: 'absolute',
  bottom: '5%',
  padding: '12px',
  borderRadius: '6px',
  background: 'rgba(255,255,255,0.8)',
});

const ActionItem = styled('div')({
  cursor: 'pointer',
  padding: '5px',
  userSelect: 'none',
  '& img': {
    cursor: 'pointer',
    width: '27px',
    height: '27px',
  },
});

const ImageCounter = styled('span')({
  background: 'rgba(20, 18, 20, 0.53)',
  padding: '3px 5px',
  margin: '5px',
  borderRadius: '3px',
  color: '#fff',
});

const imageFormatMap = new Map([
  [1, 'jpg'],
  [2, 'gif'],
  [3, 'png'],
  [4, 'bmp'],
]);

const ImageViewer: React.FC<MessageImageProps> = ({ imageMessageList, currentImageMessageID, onClose }) => {
  const [zoom, setZoom] = React.useState(1);
  const [rotate, setRotate] = React.useState(0);
  const [currentImageIndex, setCurrentImageIndex] = React.useState(
    imageMessageList?.findIndex((message: any) => message?.ID === currentImageMessageID),
  );
  const ImageViewerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event?.key === 'Escape' || event?.key === 'Esc') {
        if (ImageViewerRef.current) {
          onClose();
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    const handleWheel = (event: any) => {
      setZoom((prevZoom) => {
        if (event.deltaY > 0) {
          return Math.max(prevZoom - 0.1, 0.1); // 确保 zoom 不会小于 0.1
        }
        return prevZoom + 0.1;
      });
    };

    // 添加鼠标滚轮事件监听器
    document.addEventListener('wheel', handleWheel);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const downloadImgInWeb = () => {
    const option: any = {
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };
    const imageMessage = imageMessageList[currentImageIndex];
    const imageSrc = imageMessage?.payload?.imageInfoArray[0]?.url;
    if (!imageSrc) {
      message.error({ content: t('图片url不存在') });
      return;
    }
    const imageFormat: number = imageMessage?.payload?.imageFormat;
    if (!imageFormatMap.has(imageFormat)) {
      message.error({ content: t('暂不支持下载此类型图片') });
      return;
    }
    // If the browser supports fetch, use blob to download, so as to avoid the browser clicking the a tag and jumping to the preview of the new page
    if ((window as any).fetch) {
      fetch(imageSrc, option)
        .then((res) => res.blob())
        .then((blob) => {
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = `${url}.${imageFormatMap.get(imageFormat)}`;
          a.click();
        });
    } else {
      const a = document.createElement('a');
      a.href = imageSrc;
      a.target = '_blank';
      a.download = `${imageSrc}.${imageFormatMap.get(imageFormat)}`;
      a.click();
    }
  };

  return (
    <ImageViewerContainer ref={ImageViewerRef}>
      <ImageWrapper>
        <ImageList
          style={{
            width: `${imageMessageList.length * 100}%`,
            transform: `translateX(-${(currentImageIndex * 100) / imageMessageList.length}%)`,
            transition: '0.5s',
          }}
        >
          {imageMessageList.map((message: Message) => (
            <ImageContainer
              onClick={(e) => {
                const target = e?.target as HTMLElement;
                if (target?.tagName?.toLowerCase() !== 'img') {
                  onClose();
                }
              }}
            >
              <ImageItem
                style={{ transform: `scale(${zoom}) rotate(${rotate}deg)` }}
                src={message?.payload?.imageInfoArray[0]?.url}
              />
            </ImageContainer>
          ))}
        </ImageList>
      </ImageWrapper>
      <ImageClose
        onClick={() => {
          onClose();
        }}
      >
        <img src={iconClose}></img>
      </ImageClose>
      {currentImageIndex > 0 && (
        <ImagePreNext
          style={{ left: '10px' }}
          onClick={() => {
            if (currentImageIndex > 0) {
              setCurrentImageIndex(currentImageIndex - 1);
              setZoom(1);
              setRotate(0);
            }
          }}
        >
          <img src={iconArrowLeft}></img>
        </ImagePreNext>
      )}
      {currentImageIndex < imageMessageList.length - 1 && (
        <ImagePreNext
          style={{
            right: '10px',
            transform: 'rotate(180deg)',
          }}
          onClick={() => {
            if (currentImageIndex < imageMessageList.length - 1) {
              setCurrentImageIndex(currentImageIndex + 1);
              setZoom(1);
              setRotate(0);
            }
          }}
        >
          <img src={iconArrowLeft}></img>
        </ImagePreNext>
      )}
      <ActionsBar>
        <ActionItem
          onClick={() => {
            setZoom(zoom + 0.1);
          }}
        >
          <img src={iconZoomIn}></img>
        </ActionItem>
        <ActionItem
          onClick={() => {
            if (zoom >= 0.2) {
              setZoom(zoom - 0.1);
            }
          }}
        >
          <img src={iconZoomOut}></img>
        </ActionItem>
        <ActionItem
          onClick={() => {
            setRotate(rotate - 90);
          }}
        >
          <img src={iconRotateLeft}></img>
        </ActionItem>
        <ActionItem
          onClick={() => {
            setRotate(rotate + 90);
          }}
        >
          <img src={iconRotateRight}></img>
        </ActionItem>
        <ActionItem
          onClick={() => {
            downloadImgInWeb();
          }}
        >
          <img src={iconDownload}></img>
        </ActionItem>
        <ImageCounter>
          {currentImageIndex + 1} / {imageMessageList.length}
        </ImageCounter>
      </ActionsBar>
    </ImageViewerContainer>
  );
};

export default ImageViewer;
