import React from 'react';

import styled from 'styled-components';
import { Message } from 'tccc-sdk';

interface MessageInfoCollectProps {
  message: Message;
  data: {
    content: {
      tip: string;
      inputVariables: { variableValue: string; name: string; isRequired: number }[];
    };
    nodeStatus: number;
  };
}

const MessageInfoCollectContainer = styled.div`
  word-break: break-all;
  font-size: 14px;
  padding: 6px 12px 0 12px;
  min-width: 270px;
`;

const MessageInfoCollectTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const MessageInfoCollectItem = styled.div`
  margin-bottom: 16px;
`;

const MessageChatbotInfoCollect: React.FC<MessageInfoCollectProps> = (props) => (
  <>
    {(props?.message?.from === 'administrator' ||
      props?.message?.from === 'System message' ||
      // eslint-disable-next-line i18n/no-chinese-character
      props?.message?.from === '系统消息' ||
      props?.message?.flow === 'out') && (
      <div style={{ wordBreak: 'break-all', padding: '12px' }}>{props?.data?.content?.tip}</div>
    )}
    {props?.message?.flow === 'in' && props?.message?.from !== 'administrator' && (
      <MessageInfoCollectContainer>
        <MessageInfoCollectTitle>
          <div style={{ maxWidth: '210px' }}>{props?.data?.content?.tip}</div>
        </MessageInfoCollectTitle>
        {Array.isArray(props?.data?.content?.inputVariables) &&
          props?.data?.content?.inputVariables?.map((variable) => (
            <MessageInfoCollectItem>
              <div>
                {variable?.isRequired === 1 && <span style={{ color: 'red' }}>*</span>}
                <span style={{ paddingLeft: variable?.isRequired === 1 ? '2px' : '8px' }}>{variable?.name}</span>
              </div>
              <div style={{ marginTop: '6px', marginLeft: '10px' }}>{variable?.variableValue}</div>
            </MessageInfoCollectItem>
          ))}
      </MessageInfoCollectContainer>
    )}
  </>
);

export default MessageChatbotInfoCollect;
